import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import { useMediaQuery } from "@material-ui/core"
import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner as StaticBanner } from "../components/Banners/Pkw"
import { Banner as RoundedDynamicBanner } from "../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowToGetCard } from "../components/HowToGetCard"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"
import { InstallmentAndDeposit } from "../components/InstallmentAndDeposit"

import { getPageData } from "../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../helpers/getDynamicBannerUrl"

import { PageData } from "../interfaces/pageProps"
import { QUESTIONS_FOR_HALVA_PKW } from "../components/Questions/helpers"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerDescription = `
— Кэшбэк до 10%
<br/>
— Обслуживание 0 ₽
`

const subTitlePartners = (
  <>
    Совершайте покупки в&nbsp;магазинах-партнерах и&nbsp;получайте от&nbsp;10&nbsp;месяцев рассрочки
  </>
)

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()
  const isMobile = useMediaQuery("(max-width: 599px)")

  const bannerTitle = `
    Рассрочка от 10 ${isMobile ? "мес." : "месяцев"}
    <br/>
    по карте Халва
`

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const [longForm, setLongForm] = useState(false)

  useEffect(() => {
    if ((globalThis as any).KameleoonExperiment_271113 === 1024325) {
      setLongForm(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(globalThis as any).KameleoonExperiment_271113])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA redLogoWithSovcombank />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <StaticBanner
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          orderNum="1"
          variantStyle="mediaD"
        />
      )}
      <FourAdvantagesPoints
        withTitle
        indent
        variant="newYearMedia"
        title="Преимущества с Халвой:"
        orderNum="3"
      />
      <FormPKW
        progressBar
        dataLayerName={longForm ? "longPersonalForm" : "shortPersonalForm"}
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm={longForm}
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="4"
        onlyPKWPage
        isGreenApprove
      />
      <HowToGetCard orderNum="5" />
      <NewCalculator additionalEventInDataLayer orderNum="6" />
      <InstallmentAndDeposit orderNum="7" />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="8"
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="9"
        hasSubtitle
        bottomBlock={false}
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="10" />
      <Reviews additionalEventInDataLayer orderNum="11" />
      <Questions questionList={QUESTIONS_FOR_HALVA_PKW} orderNum="12" />
      <NewFooter ligal={ligal} orderNum="13" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/media-d/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
